.loader-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    z-index: 99;
    top: 35%;
    left: 3%;
    width: 100vw;
    height: 100vh;
}
.loader-overlay{
    position: fixed;
    z-index: 98;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    background-color: rgba(67, 68, 68, 0.7);
    
}
.loader {
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 10px;
    img {
        width: 100px;
        height: 100px;
    }
}

.internal-loader{
    margin: auto;
    .loader-overlay{
        top: 0;
        left: 0;
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.table-responsive {
    overflow: hidden;
}

.modal-header {
    a {
        font-size: 22px;
    }
}

.showmodel {
    .modal {
        display: block;
    }
}
.modal {
    .modal-dialog {
        z-index: 99;
    }
}
.table-bordered {
    td, th {
        padding: 5px;
        font-size: 16px;
    }
    th {
        font-weight: bold;
    }
    
}

.overlay {
    position: fixed;
    z-index: 98;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    background-color: #000;
}
.alert-container{
    position: fixed;
    top: 15%;
    right: 3%;
    z-index: 99;
    .alert{
        width: 76vw;
    }
}

.alert-container .alert {
  position: fixed;
  right: 1%;
  width: 18%;
  top: 1%;
}